/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountClassification {
  account = "account",
  benchmark = "benchmark",
  custodial = "custodial",
  fund = "fund",
  mandate = "mandate",
}

export enum AccountStatus {
  delayed = "delayed",
  inactive = "inactive",
  pending = "pending",
  published = "published",
  revalidated = "revalidated",
  validated = "validated",
}

export enum AccountType {
  bank = "bank",
  clientPaidOut = "clientPaidOut",
  clientPrincipal = "clientPrincipal",
  composite = "composite",
  container = "container",
  corporation = "corporation",
  firm = "firm",
  fund = "fund",
  fx = "fx",
  investment = "investment",
  mandate = "mandate",
  market = "market",
  mvWeightedAverage = "mvWeightedAverage",
  pme = "pme",
  policyWeightedAverage = "policyWeightedAverage",
  portfolioCompany = "portfolioCompany",
  privateAsset = "privateAsset",
  property = "property",
  security = "security",
  spliced = "spliced",
  subMandate = "subMandate",
  summary = "summary",
  total = "total",
  trustee = "trustee",
  virtual = "virtual",
}

export enum AccountVehicle {
  commingledFund = "commingledFund",
  direct = "direct",
  etf = "etf",
  fundOfFunds = "fundOfFunds",
  investmentTrust = "investmentTrust",
  limitedPartnetships = "limitedPartnetships",
  mutualFund = "mutualFund",
  separateAccount = "separateAccount",
  seperateAccount = "seperateAccount",
}

export enum AssetType {
  benchmark = "benchmark",
  fund = "fund",
  portfolio = "portfolio",
}

export enum CategoryType {
  assetOwner = "assetOwner",
  consultant = "consultant",
  custom = "custom",
  regulatory = "regulatory",
  text = "text",
  visualalpha = "visualalpha",
}

export enum ClassificationType {
  content = "content",
  data = "data",
  report = "report",
}

export enum CompanyRole {
  custodian = "custodian",
  fundAdministrator = "fundAdministrator",
  investor = "investor",
  manager = "manager",
  masterTrustee = "masterTrustee",
  subAdvisor = "subAdvisor",
  trustee = "trustee",
}

export enum CounterpartyType {
  FX = "FX",
  broker = "broker",
  issuer = "issuer",
}

export enum CurrencyType {
  base = "base",
  local = "local",
}

export enum DatasetStatus {
  published = "published",
  validated = "validated",
}

export enum DatasetType {
  accounts = "accounts",
  benchmarks = "benchmarks",
  fixedincome = "fixedincome",
  funds = "funds",
  mandates = "mandates",
}

export enum DateBasis {
  settlement = "settlement",
  trade = "trade",
}

export enum EntityNoteType {
  assetManager = "assetManager",
  assetOwner = "assetOwner",
}

export enum ExportSchemaType {
  accounts = "accounts",
  asset = "asset",
  asset_classes = "asset_classes",
  mandates = "mandates",
}

export enum FundSecurityFinancialsAuthenticity {
  Audited = "Audited",
  Estimated = "Estimated",
  Valuated = "Valuated",
}

export enum GrossOrNet {
  Gross = "Gross",
  Net = "Net",
  gross = "gross",
  net = "net",
}

export enum InstructionStatus {
  cancelled = "cancelled",
  final = "final",
  finalSetoff = "finalSetoff",
  finalSplit = "finalSplit",
  invalid = "invalid",
  preliminary = "preliminary",
}

export enum InsxType {
  bookValueAdjustment = "bookValueAdjustment",
  buy = "buy",
  cashTransfer = "cashTransfer",
  contribution = "contribution",
  distribution = "distribution",
  dividend = "dividend",
  fee = "fee",
  fxForward = "fxForward",
  fxForwardSetoff = "fxForwardSetoff",
  fxForwardSplit = "fxForwardSplit",
  interestAdjustment = "interestAdjustment",
  interestEarned = "interestEarned",
  miscProfitAndLoss = "miscProfitAndLoss",
  navAdjustment = "navAdjustment",
  none = "none",
  payout = "payout",
  rebate = "rebate",
  receiptAdditional = "receiptAdditional",
  reinvestmentFund = "reinvestmentFund",
  reinvestmentPrivateAsset = "reinvestmentPrivateAsset",
  sell = "sell",
  unitAdjustment = "unitAdjustment",
  withholdingTax = "withholdingTax",
}

export enum MessageSeverity {
  error = "error",
  warning = "warning",
}

export enum OrderByDir {
  asc = "asc",
  desc = "desc",
}

export enum PackageType {
  accounts = "accounts",
  benchmarks = "benchmarks",
  corporations = "corporations",
  fixedincome = "fixedincome",
  funds = "funds",
  fxrates = "fxrates",
  holdings = "holdings",
  instructions = "instructions",
  mandates = "mandates",
  securities = "securities",
  securityprices = "securityprices",
}

export enum ReportingType {
  default = "default",
  privateAsset = "privateAsset",
}

export enum RiskMethod {
  standardDeviation = "standardDeviation",
}

export enum RiskType {
  volatility = "volatility",
}

export enum SecurityPriceStatus {
  cancelled = "cancelled",
  final = "final",
}

export enum TaskStatus {
  delayed = "delayed",
  pending = "pending",
  published = "published",
  revalidated = "revalidated",
  validated = "validated",
}

export enum Tenor {
  fiveMonthForward = "fiveMonthForward",
  fourMonthForward = "fourMonthForward",
  interpolated = "interpolated",
  oneDayForward = "oneDayForward",
  oneMonthForward = "oneMonthForward",
  oneWeekForward = "oneWeekForward",
  oneYearForward = "oneYearForward",
  overnightForward = "overnightForward",
  sixMonthForward = "sixMonthForward",
  spot = "spot",
  threeMonthForward = "threeMonthForward",
  threeWeekForward = "threeWeekForward",
  threeYearForward = "threeYearForward",
  tommorowForward = "tommorowForward",
  twoDayForward = "twoDayForward",
  twoMonthForward = "twoMonthForward",
  twoWeekForward = "twoWeekForward",
  twoYearForward = "twoYearForward",
}

export enum UploadMode {
  async = "async",
  sync = "sync",
}

export enum UploadStatus {
  approved = "approved",
  created = "created",
  error = "error",
  preprocessed = "preprocessed",
  processing = "processing",
  revalidated = "revalidated",
  staged = "staged",
  validated = "validated",
  warning = "warning",
}

export enum UserPriceFrequency {
  daily = "daily",
  irregularly = "irregularly",
  monthly = "monthly",
  quarterly = "quarterly",
  weekly = "weekly",
}

export enum UserRole {
  admin = "admin",
  readonly = "readonly",
  user = "user",
}

export enum ValuationType {
  bookValue = "bookValue",
  gross = "gross",
  net = "net",
}

export enum ValueSign {
  negative = "negative",
  positive = "positive",
}

/**
 * Input filter for the {filter} argument on the query {asset}
 */
export interface AccountAssetsFilter {
  organisation?: AssetOrganisationsFilter | null;
  OR?: AccountAssetsFilter[] | null;
  NOT?: AccountAssetsFilter[] | null;
}

export interface AccountClassificationFilter {
  equals?: AccountClassification | null;
  not?: AccountClassification | null;
  in?: AccountClassification[] | null;
  notIn?: AccountClassification[] | null;
}

/**
 * Input filter for the {filter} argument on the query {companies}
 */
export interface AccountCompaniesFilter {
  role?: CompanyRoleFilter | null;
  masterTrustee?: CompanyMasterTrusteesFilter | null;
  trustee?: CompanyTrusteesFilter | null;
  manager?: CompanyManagersFilter | null;
  OR?: AccountCompaniesFilter[] | null;
  NOT?: AccountCompaniesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface AccountCurrenciesFilter {
  code?: StringFilter | null;
  name?: StringFilter | null;
  OR?: AccountCurrenciesFilter[] | null;
  NOT?: AccountCurrenciesFilter[] | null;
}

export interface AccountPackageInput {
  organisation_package_id: string;
  reporting_organisation_id?: string | null;
  start: any;
  end?: any | null;
}

export interface AccountTypeFilter {
  equals?: AccountType | null;
  not?: AccountType | null;
  in?: AccountType[] | null;
  notIn?: AccountType[] | null;
}

export interface AccountVehicleFilter {
  equals?: AccountVehicle | null;
  not?: AccountVehicle | null;
  in?: AccountVehicle[] | null;
  notIn?: AccountVehicle[] | null;
}

export interface AssetCategoryPivot {
  id: string;
  order: number;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface AssetCurrenciesFilter {
  code?: StringFilter | null;
  name?: StringFilter | null;
  OR?: AssetCurrenciesFilter[] | null;
  NOT?: AssetCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation}
 */
export interface AssetOrganisationsFilter {
  name?: StringFilter | null;
  OR?: AssetOrganisationsFilter[] | null;
  NOT?: AssetOrganisationsFilter[] | null;
}

export interface AssetTypeFilter {
  equals?: AssetType | null;
  not?: AssetType | null;
  in?: AssetType[] | null;
  notIn?: AssetType[] | null;
}

/**
 * Input filter for the {filter} argument on the query {bankAccountType}
 */
export interface BankAccountBankAccountTypesFilter {
  type?: StringFilter | null;
  OR?: BankAccountBankAccountTypesFilter[] | null;
  NOT?: BankAccountBankAccountTypesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {fundBankAccount}
 */
export interface BankAccountFundBankAccountsFilter {
  fundBankAccountRole?: FundBankAccountFundBankAccountRolesFilter | null;
  OR?: BankAccountFundBankAccountsFilter[] | null;
  NOT?: BankAccountFundBankAccountsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {fund}
 */
export interface BankAccountFundsFilter {
  name?: StringFilter | null;
  OR?: BankAccountFundsFilter[] | null;
  NOT?: BankAccountFundsFilter[] | null;
}

export interface BooleanFilter {
  equals?: boolean | null;
  not?: boolean | null;
  in?: boolean[] | null;
  notIn?: boolean[] | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface CashflowCurrenciesFilter {
  code?: StringFilter | null;
  OR?: CashflowCurrenciesFilter[] | null;
  NOT?: CashflowCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {entity}
 */
export interface CashflowEntitiesFilter {
  name?: StringFilter | null;
  ref?: StringFilter | null;
  OR?: CashflowEntitiesFilter[] | null;
  NOT?: CashflowEntitiesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {instruction}
 */
export interface CashflowInstructionsFilter {
  id?: IDFilter | null;
  type_description?: StringFilter | null;
  category_description?: StringFilter | null;
  OR?: CashflowInstructionsFilter[] | null;
  NOT?: CashflowInstructionsFilter[] | null;
}

export interface ClassificationTypeFilter {
  equals?: ClassificationType | null;
  not?: ClassificationType | null;
  in?: ClassificationType[] | null;
  notIn?: ClassificationType[] | null;
}

export interface CompanyInput {
  organisation_id: string;
  role: CompanyRole;
  ref?: string | null;
}

/**
 * Input filter for the {filter} argument on the query {manager}
 */
export interface CompanyManagersFilter {
  organisation?: ManagerOrganisationsFilter | null;
  ref?: StringFilter | null;
  OR?: CompanyManagersFilter[] | null;
  NOT?: CompanyManagersFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {masterTrustee}
 */
export interface CompanyMasterTrusteesFilter {
  organisation?: MasterTrusteeOrganisationsFilter | null;
  ref?: StringFilter | null;
  OR?: CompanyMasterTrusteesFilter[] | null;
  NOT?: CompanyMasterTrusteesFilter[] | null;
}

export interface CompanyRoleFilter {
  equals?: CompanyRole | null;
  not?: CompanyRole | null;
  in?: CompanyRole[] | null;
  notIn?: CompanyRole[] | null;
}

/**
 * Input filter for the {filter} argument on the query {trustee}
 */
export interface CompanyTrusteesFilter {
  organisation?: TrusteeOrganisationsFilter | null;
  ref?: StringFilter | null;
  OR?: CompanyTrusteesFilter[] | null;
  NOT?: CompanyTrusteesFilter[] | null;
}

export interface CreateFile {
  report_id: string;
  file: any;
}

export interface CreateReportUploadHasOne {
  create: CreateReportUploadInput;
}

export interface CreateReportUploadInput {
  asset_id: string;
  as_of_date: any;
}

export interface DateFilter {
  equals?: any | null;
  not?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  contains?: any | null;
}

export interface DateTimeFilter {
  equals?: any | null;
  not?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
}

export interface DynamicCashflowInput {
  type: string;
  value: number;
}

export interface EntityBenchmarkPivot {
  id: string;
  benchmark_type: string;
  benchmark_type_id: string;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface ExportTransactionCurrenciesFilter {
  code?: StringFilter | null;
  OR?: ExportTransactionCurrenciesFilter[] | null;
  NOT?: ExportTransactionCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {entity}
 */
export interface ExportTransactionEntitiesFilter {
  name?: StringFilter | null;
  OR?: ExportTransactionEntitiesFilter[] | null;
  NOT?: ExportTransactionEntitiesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {security}
 */
export interface ExportTransactionSecuritiesFilter {
  name?: StringFilter | null;
  OR?: ExportTransactionSecuritiesFilter[] | null;
  NOT?: ExportTransactionSecuritiesFilter[] | null;
}

export interface FloatFilter {
  equals?: number | null;
  not?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  contains?: number | null;
}

/**
 * Input filter for the {filter} argument on the query {fundBankAccountRole}
 */
export interface FundBankAccountFundBankAccountRolesFilter {
  role?: StringFilter | null;
  OR?: FundBankAccountFundBankAccountRolesFilter[] | null;
  NOT?: FundBankAccountFundBankAccountRolesFilter[] | null;
}

export interface IDFilter {
  equals?: string | null;
  not?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

/**
 * Input filter for the {filter} argument on the query {instruction_report_type}
 */
export interface InstructionLivedocInstructionReportTypesFilter {
  input?: InstructionReportTypeInputsFilter | null;
  OR?: InstructionLivedocInstructionReportTypesFilter[] | null;
  NOT?: InstructionLivedocInstructionReportTypesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation_instruction_type}
 */
export interface InstructionLivedocOrganisationInstructionTypesFilter {
  input?: OrganisationInstructionTypeInputsFilter | null;
  OR?: InstructionLivedocOrganisationInstructionTypesFilter[] | null;
  NOT?: InstructionLivedocOrganisationInstructionTypesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {input}
 */
export interface InstructionReportTypeInputsFilter {
  type?: StringFilter | null;
  OR?: InstructionReportTypeInputsFilter[] | null;
  NOT?: InstructionReportTypeInputsFilter[] | null;
}

export interface IntFilter {
  equals?: number | null;
  not?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
}

/**
 * Input filter for the {filter} argument on the query {job}
 */
export interface JobInstanceDetailJobsFilter {
  id?: IDFilter | null;
  name?: StringFilter | null;
  OR?: JobInstanceDetailJobsFilter[] | null;
  NOT?: JobInstanceDetailJobsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {status}
 */
export interface JobInstanceDetailStatusesFilter {
  id?: IDFilter | null;
  name?: StringFilter | null;
  OR?: JobInstanceDetailStatusesFilter[] | null;
  NOT?: JobInstanceDetailStatusesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation}
 */
export interface ManagerOrganisationsFilter {
  id?: IDFilter | null;
  name?: StringFilter | null;
  OR?: ManagerOrganisationsFilter[] | null;
  NOT?: ManagerOrganisationsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation}
 */
export interface MasterTrusteeOrganisationsFilter {
  id?: IDFilter | null;
  name?: StringFilter | null;
  OR?: MasterTrusteeOrganisationsFilter[] | null;
  NOT?: MasterTrusteeOrganisationsFilter[] | null;
}

export interface OptionBenchmarkPivot {
  id: string;
  benchmark_type?: string | null;
}

/**
 * Input filter for the {filter} argument on the query {assets}
 */
export interface OrganisationAssetsFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  title?: StringFilter | null;
  type?: AssetTypeFilter | null;
  currency_id?: IntFilter | null;
  fiscal_year_start?: IntFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  currency?: AssetCurrenciesFilter | null;
  OR?: OrganisationAssetsFilter[] | null;
  NOT?: OrganisationAssetsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {assets}
 */
export interface OrganisationAssetsOrderBy {
  id?: OrderByDir | null;
  name?: OrderByDir | null;
  title?: OrderByDir | null;
  fiscal_year_start?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {country}
 */
export interface OrganisationCountriesFilter {
  name?: StringFilter | null;
  OR?: OrganisationCountriesFilter[] | null;
  NOT?: OrganisationCountriesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {country}
 */
export interface OrganisationFundCountriesFilter {
  name?: StringFilter | null;
  OR?: OrganisationFundCountriesFilter[] | null;
  NOT?: OrganisationFundCountriesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface OrganisationFundCurrenciesFilter {
  code?: StringFilter | null;
  name?: StringFilter | null;
  OR?: OrganisationFundCurrenciesFilter[] | null;
  NOT?: OrganisationFundCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {input}
 */
export interface OrganisationInstructionTypeInputsFilter {
  organisation_id?: IDFilter | null;
  type?: StringFilter | null;
  OR?: OrganisationInstructionTypeInputsFilter[] | null;
  NOT?: OrganisationInstructionTypeInputsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation}
 */
export interface OrganisationPackageOrganisationsFilter {
  name?: StringFilter | null;
  ref?: StringFilter | null;
  OR?: OrganisationPackageOrganisationsFilter[] | null;
  NOT?: OrganisationPackageOrganisationsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {package}
 */
export interface OrganisationPackagePackagesFilter {
  name?: StringFilter | null;
  classification?: ClassificationTypeFilter | null;
  ref?: StringFilter | null;
  OR?: OrganisationPackagePackagesFilter[] | null;
  NOT?: OrganisationPackagePackagesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {uploads}
 */
export interface OrganisationUploadsFilter {
  package_id?: IntFilter | null;
  period_id?: IntFilter | null;
  asset_id?: IntFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  OR?: OrganisationUploadsFilter[] | null;
  NOT?: OrganisationUploadsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {uploads}
 */
export interface OrganisationUploadsOrderBy {
  id?: OrderByDir | null;
  package_id?: OrderByDir | null;
  period_id?: OrderByDir | null;
  asset_id?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {users}
 */
export interface OrganisationUsersOrderBy {
  id?: OrderByDir | null;
  name?: OrderByDir | null;
  email?: OrderByDir | null;
  role?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {accounts}
 */
export interface QueryAccountsFilter {
  ref?: StringFilter | null;
  name?: StringFilter | null;
  short_name?: StringFilter | null;
  classification?: AccountClassificationFilter | null;
  type?: AccountTypeFilter | null;
  vehicle?: AccountVehicleFilter | null;
  opened?: DateFilter | null;
  closed?: DateFilter | null;
  performance_inception?: DateFilter | null;
  performance_closed?: DateFilter | null;
  link?: BooleanFilter | null;
  currency?: AccountCurrenciesFilter | null;
  organisation_name?: StringFilter | null;
  asset?: AccountAssetsFilter | null;
  companies?: AccountCompaniesFilter | null;
  parent_id?: IDFilter | null;
  organisation_package_id?: IDFilter | null;
  OR?: QueryAccountsFilter[] | null;
  NOT?: QueryAccountsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {accounts}
 */
export interface QueryAccountsOrderBy {
  order?: OrderByDir | null;
  ref?: OrderByDir | null;
  name?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {allFxRates}
 */
export interface QueryAllFxRatesFilter {
  organisation_id?: IDFilter | null;
  base_currency_id?: IDFilter | null;
  base_currency_entity_id?: IDFilter | null;
  counter_currency_id?: IDFilter | null;
  counter_currency_entity_id?: IDFilter | null;
  baseCurrency?: StringFilter | null;
  counterCurrency?: StringFilter | null;
  tenor?: TenorFilter | null;
  source?: StringFilter | null;
  date?: DateFilter | null;
  rate?: FloatFilter | null;
  bidAsk?: StringFilter | null;
  created_at?: DateTimeFilter | null;
  created_by?: StringFilter | null;
  OR?: QueryFxRatesFilter[] | null;
  NOT?: QueryFxRatesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {allInstructions}
 */
export interface QueryAllInstructionsFilter {
  id?: IDFilter | null;
  from_entity_id?: IDFilter | null;
  to_entity_id?: IDFilter | null;
  from_entity_name?: StringFilter | null;
  to_entity_name?: StringFilter | null;
  status?: StringFilter | null;
  client_name?: StringFilter | null;
  mandate_name?: StringFilter | null;
  mandate_id?: IDFilter | null;
  date?: DateFilter | null;
  security_name?: StringFilter | null;
  category_description?: StringFilter | null;
  type_description?: StringFilter | null;
  created_by?: StringFilter | null;
  OR?: QueryAllInstructionsFilter[] | null;
  NOT?: QueryAllInstructionsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {assets}
 */
export interface QueryAssetsFilter {
  name?: StringFilter | null;
  ref?: StringFilter | null;
  type?: AssetTypeFilter | null;
  currency_id?: IntFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  currency?: AssetCurrenciesFilter | null;
  OR?: QueryAssetsFilter[] | null;
  NOT?: QueryAssetsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {assets}
 */
export interface QueryAssetsOrderBy {
  id?: OrderByDir | null;
  name?: OrderByDir | null;
  opened?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {bankAccounts}
 */
export interface QueryBankAccountsFilter {
  account_number?: StringFilter | null;
  account_name?: StringFilter | null;
  account_name_kana?: StringFilter | null;
  aba_routing_number?: StringFilter | null;
  iban?: StringFilter | null;
  bankAccountType?: BankAccountBankAccountTypesFilter | null;
  fund?: BankAccountFundsFilter | null;
  fundBankAccount?: BankAccountFundBankAccountsFilter | null;
  OR?: QueryBankAccountsFilter[] | null;
  NOT?: QueryBankAccountsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {banks}
 */
export interface QueryBanksFilter {
  name?: StringFilter | null;
  OR?: QueryBanksFilter[] | null;
  NOT?: QueryBanksFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {branches}
 */
export interface QueryBranchesFilter {
  name?: StringFilter | null;
  swift_bic?: StringFilter | null;
  OR?: QueryBranchesFilter[] | null;
  NOT?: QueryBranchesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {cashflows}
 */
export interface QueryCashflowsFilter {
  id?: IDFilter | null;
  manager_id?: IDFilter | null;
  entity_id?: IDFilter | null;
  instruction_id?: IDFilter | null;
  trade_date?: DateFilter | null;
  settlement_date?: DateFilter | null;
  status?: StringFilter | null;
  mandate_id?: IDFilter | null;
  mandate_name?: StringFilter | null;
  client_name?: StringFilter | null;
  client_ref?: StringFilter | null;
  mandate_ref?: StringFilter | null;
  instruction?: CashflowInstructionsFilter | null;
  entity?: CashflowEntitiesFilter | null;
  currency?: CashflowCurrenciesFilter | null;
  type?: StringFilter | null;
  amount?: FloatFilter | null;
  balance?: FloatFilter | null;
  forecast_balance?: FloatFilter | null;
  OR?: QueryCashflowsFilter[] | null;
  NOT?: QueryCashflowsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {exportTransactions}
 */
export interface QueryExportTransactionsFilter {
  entity_id?: IDFilter | null;
  entity?: ExportTransactionEntitiesFilter | null;
  instruction_id?: IDFilter | null;
  mandate_id?: IDFilter | null;
  status?: StringFilter | null;
  type?: StringFilter | null;
  price?: FloatFilter | null;
  unit?: FloatFilter | null;
  amount?: FloatFilter | null;
  security_id?: IDFilter | null;
  security?: ExportTransactionSecuritiesFilter | null;
  currency?: ExportTransactionCurrenciesFilter | null;
  trade_date?: DateFilter | null;
  settlement_date?: DateFilter | null;
  OR?: QueryExportTransactionsFilter[] | null;
  NOT?: QueryExportTransactionsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {fxRates}
 */
export interface QueryFxRatesFilter {
  organisation_id?: IDFilter | null;
  base_currency_id?: IDFilter | null;
  base_currency_entity_id?: IDFilter | null;
  counter_currency_id?: IDFilter | null;
  counter_currency_entity_id?: IDFilter | null;
  baseCurrency?: StringFilter | null;
  counterCurrency?: StringFilter | null;
  tenor?: TenorFilter | null;
  source?: StringFilter | null;
  date?: DateFilter | null;
  rate?: FloatFilter | null;
  bidAsk?: StringFilter | null;
  created_at?: DateTimeFilter | null;
  created_by?: StringFilter | null;
  OR?: QueryFxRatesFilter[] | null;
  NOT?: QueryFxRatesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {instructionLivedocs}
 */
export interface QueryInstructionLivedocsFilter {
  organisation_instruction_type?: InstructionLivedocOrganisationInstructionTypesFilter | null;
  instruction_report_type?: InstructionLivedocInstructionReportTypesFilter | null;
  OR?: QueryInstructionLivedocsFilter[] | null;
  NOT?: QueryInstructionLivedocsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {instructions}
 */
export interface QueryInstructionsFilter {
  id?: IDFilter | null;
  from_entity_id?: IDFilter | null;
  to_entity_id?: IDFilter | null;
  from_entity_name?: StringFilter | null;
  to_entity_name?: StringFilter | null;
  client_name?: StringFilter | null;
  mandate_name?: StringFilter | null;
  client_ref?: StringFilter | null;
  mandate_ref?: StringFilter | null;
  status?: StringFilter | null;
  mandate_id?: IDFilter | null;
  date?: DateFilter | null;
  security_name?: StringFilter | null;
  category_description?: StringFilter | null;
  type_description?: StringFilter | null;
  created_by?: StringFilter | null;
  OR?: QueryInstructionsFilter[] | null;
  NOT?: QueryInstructionsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {jobInstanceDetails}
 */
export interface QueryJobInstanceDetailsFilter {
  id?: IDFilter | null;
  job?: JobInstanceDetailJobsFilter | null;
  status?: JobInstanceDetailStatusesFilter | null;
  mandate_id?: IDFilter | null;
  start_date?: DateFilter | null;
  start_time?: DateTimeFilter | null;
  end_time?: DateTimeFilter | null;
  OR?: QueryJobInstanceDetailsFilter[] | null;
  NOT?: QueryJobInstanceDetailsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisationFunds}
 */
export interface QueryOrganisationFundsFilter {
  name?: StringFilter | null;
  ref?: StringFilter | null;
  currency?: OrganisationFundCurrenciesFilter | null;
  country?: OrganisationFundCountriesFilter | null;
  fund_size?: FloatFilter | null;
  investment_period_end_date?: DateFilter | null;
  inception_date?: DateFilter | null;
  first_cashflow_date?: DateFilter | null;
  OR?: QueryOrganisationFundsFilter[] | null;
  NOT?: QueryOrganisationFundsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisationPackages}
 */
export interface QueryOrganisationPackagesFilter {
  name?: StringFilter | null;
  type?: StringFilter | null;
  ref?: StringFilter | null;
  package?: OrganisationPackagePackagesFilter | null;
  organisation?: OrganisationPackageOrganisationsFilter | null;
  frequency?: StringFilter | null;
  deadline?: IntFilter | null;
  reported?: BooleanFilter | null;
  OR?: QueryOrganisationPackagesFilter[] | null;
  NOT?: QueryOrganisationPackagesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisations}
 */
export interface QueryOrganisationsFilter {
  name?: StringFilter | null;
  local_name?: StringFilter | null;
  type?: StringFilter | null;
  organisation_type_id?: IntFilter | null;
  ref?: StringFilter | null;
  asset_id?: IntFilter | null;
  country?: OrganisationCountriesFilter | null;
  modules?: StringFilter | null;
  trial?: BooleanFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  fys?: StringFilter | null;
  OR?: QueryOrganisationsFilter[] | null;
  NOT?: QueryOrganisationsFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {securities}
 */
export interface QuerySecuritiesFilter {
  manager_id?: IDFilter | null;
  ref?: StringFilter | null;
  user_code?: StringFilter | null;
  type1?: StringFilter | null;
  type2?: StringFilter | null;
  active?: BooleanFilter | null;
  currency?: SecurityCurrenciesFilter | null;
  user_price_frequency?: StringFilter | null;
  created_at?: DateFilter | null;
  created_by?: StringFilter | null;
  name?: StringFilter | null;
  default_types?: StringFilter | null;
  OR?: QuerySecuritiesFilter[] | null;
  NOT?: QuerySecuritiesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {securityPrices}
 */
export interface QuerySecurityPricesFilter {
  security_id?: IDFilter | null;
  manager_id?: IDFilter | null;
  currency_id?: IDFilter | null;
  source?: StringFilter | null;
  date?: DateFilter | null;
  effective_date?: DateFilter | null;
  status?: StringFilter | null;
  created_by?: StringFilter | null;
  created_at?: DateTimeFilter | null;
  price?: FloatFilter | null;
  comment?: StringFilter | null;
  currency?: SecurityPriceCurrenciesFilter | null;
  OR?: QuerySecurityPricesFilter[] | null;
  NOT?: QuerySecurityPricesFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {securityPrices}
 */
export interface QuerySecurityPricesOrderBy {
  effective_date?: OrderByDir | null;
  created_at?: OrderByDir | null;
  date?: OrderByDir | null;
  price?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {transactions}
 */
export interface QueryTransactionsFilter {
  id?: IDFilter | null;
  manager_id?: IDFilter | null;
  entity_id?: IDFilter | null;
  entity?: TransactionEntitiesFilter | null;
  instruction_id?: IDFilter | null;
  mandate_id?: IDFilter | null;
  mandate_name?: StringFilter | null;
  client_name?: StringFilter | null;
  client_ref?: StringFilter | null;
  mandate_ref?: StringFilter | null;
  status?: StringFilter | null;
  type?: StringFilter | null;
  price?: FloatFilter | null;
  unit?: FloatFilter | null;
  amount?: FloatFilter | null;
  security_id?: IDFilter | null;
  security?: TransactionSecuritiesFilter | null;
  currency?: TransactionCurrenciesFilter | null;
  trade_date?: DateFilter | null;
  settlement_date?: DateFilter | null;
  OR?: QueryTransactionsFilter[] | null;
  NOT?: QueryTransactionsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {transactions}
 */
export interface QueryTransactionsOrderBy {
  trade_date?: OrderByDir | null;
  settlement_date?: OrderByDir | null;
  id?: OrderByDir | null;
  created_at?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {uploads}
 */
export interface QueryUploadsFilter {
  id?: IDFilter | null;
  organisation_package_id?: IDFilter | null;
  period_id?: IntFilter | null;
  reportUpload?: UploadReportUploadsFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  OR?: QueryUploadsFilter[] | null;
  NOT?: QueryUploadsFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {uploads}
 */
export interface QueryUploadsOrderBy {
  id?: OrderByDir | null;
  organisation_package_id?: OrderByDir | null;
  period_id?: OrderByDir | null;
  asset_id?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {users}
 */
export interface QueryUsersFilter {
  name?: StringFilter | null;
  email?: StringFilter | null;
  phone_number?: StringFilter | null;
  role?: UserRoleFilter | null;
  superuser?: BooleanFilter | null;
  created_at?: DateTimeFilter | null;
  updated_at?: DateTimeFilter | null;
  OR?: QueryUsersFilter[] | null;
  NOT?: QueryUsersFilter[] | null;
}

/**
 * Input orderBy for the {orderBy} argument on the query {users}
 */
export interface QueryUsersOrderBy {
  name?: OrderByDir | null;
  email?: OrderByDir | null;
  role?: OrderByDir | null;
  superuser?: OrderByDir | null;
  created_at?: OrderByDir | null;
  updated_at?: OrderByDir | null;
  organisation_name?: OrderByDir | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface SecurityCurrenciesFilter {
  code?: StringFilter | null;
  name?: StringFilter | null;
  OR?: SecurityCurrenciesFilter[] | null;
  NOT?: SecurityCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface SecurityPriceCurrenciesFilter {
  code?: StringFilter | null;
  name?: StringFilter | null;
  OR?: SecurityPriceCurrenciesFilter[] | null;
  NOT?: SecurityPriceCurrenciesFilter[] | null;
}

export interface StringFilter {
  equals?: string | null;
  not?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
}

export interface TenorFilter {
  equals?: Tenor | null;
  not?: Tenor | null;
  in?: Tenor[] | null;
  notIn?: Tenor[] | null;
}

/**
 * Input filter for the {filter} argument on the query {currency}
 */
export interface TransactionCurrenciesFilter {
  code?: StringFilter | null;
  OR?: TransactionCurrenciesFilter[] | null;
  NOT?: TransactionCurrenciesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {entity}
 */
export interface TransactionEntitiesFilter {
  name?: StringFilter | null;
  OR?: TransactionEntitiesFilter[] | null;
  NOT?: TransactionEntitiesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {security}
 */
export interface TransactionSecuritiesFilter {
  name?: StringFilter | null;
  OR?: TransactionSecuritiesFilter[] | null;
  NOT?: TransactionSecuritiesFilter[] | null;
}

/**
 * Input filter for the {filter} argument on the query {organisation}
 */
export interface TrusteeOrganisationsFilter {
  id?: IDFilter | null;
  name?: StringFilter | null;
  OR?: TrusteeOrganisationsFilter[] | null;
  NOT?: TrusteeOrganisationsFilter[] | null;
}

export interface UpdateBenchmarksBelongsToMany {
  sync?: OptionBenchmarkPivot[] | null;
}

export interface UpdateCategoryInput {
  id: string;
}

export interface UpdateOptionInput {
  id?: string | null;
  name: string;
  target?: number | null;
  tolerance?: number | null;
  split?: any | null;
  reporting_type?: ReportingType | null;
  benchmarks?: UpdateBenchmarksBelongsToMany | null;
  order: number;
  subCategory?: UpdateSubCategoryHasOne | null;
}

export interface UpdateSubCategoryHasOne {
  connect?: string | null;
  update?: UpdateSubCategoryInput | null;
}

export interface UpdateSubCategoryInput {
  id?: string | null;
  name: string;
}

/**
 * Input filter for the {filter} argument on the query {reportUpload}
 */
export interface UploadReportUploadsFilter {
  asset_id?: IntFilter | null;
  OR?: UploadReportUploadsFilter[] | null;
  NOT?: UploadReportUploadsFilter[] | null;
}

export interface UserRoleFilter {
  equals?: UserRole | null;
  not?: UserRole | null;
  in?: UserRole[] | null;
  notIn?: UserRole[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
